.formula-container{
    /* border:1px solid red; */
    width:1100px;
    margin:0px auto;}

    .intro-paragraph-container{
        width:780px;
        margin:auto;
        margin:20px auto 80px auto;
    }
    
    .intro-paragraph{
        font-size:20px;
        font-weight:200;
        line-height: 1.4;
    }
    .formula-name-container{
        width:100%;
        height:45px;
        /* border-bottom: 1px solid rgb(255, 176, 176); */
    }

        .formula-name{
            padding-left: 20px;
            color:rgb(87, 87, 87);
            font-size:32px;
            font-family: 'Rajdhani', sans-serif;}

            .projects-title{
                color:rgb(248, 100, 100);
                margin-bottom:10px;
                padding-left:20px;
                font-size:25px;
            }

            
@media only screen and (max-width: 1100px) {
    .formula-container{
        /* border:1px solid red; */
        width:100%;
        margin:0px auto;}
    
        .intro-paragraph-container{
            width:80vw;
            max-width:800px;
            margin:auto;
            margin:0px auto 80px auto;
            height:2vw;
        }
        
        .intro-paragraph{
            font-size:2vw;

            font-weight:200;
            line-height: 1.4;
        }
        .formula-name-container{
            width:100%;
            height:6vw;
            /* border: 1px solid rgb(255, 176, 176); */
        }
    
            .formula-name{
                padding-left: 9vw;
                color:rgb(87, 87, 87);
                font-size:4vw;
                font-family: 'Rajdhani', sans-serif;}
    
                .projects-title{
                    color:rgb(248, 100, 100);
                    margin-bottom:10px;
                    padding-left:20px;
                    font-size:3vw;
                }
}